import axios from "@/axios.js";

export default {
  async fetchFoodMerchants({ commit }) {
    try {
      const { data } = await axios.get(`/vancynet/food-merchant`);
      commit("setFoodMerchants", data.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchFoodMerchant({ commit }, params) {
    try {
      const { data } = await axios.get(`/vancynet/food-merchant/${params}`);
      return { success: true, message: data.data };
    } catch (error) {
      return { success: false, message: error.message };
    }
  },
  async storeFoodMerchant({ commit }, params) {
    try {
      const { data } = await axios.post(`/vancynet/food-merchant`, params);
      return { success: true };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message,
      };
    }
  },
  async updateFoodMerchant({ commit }, params) {
    try {
      const { data } = await axios.post(
        `/vancynet/food-merchant/${params.id}`,
        params.body
      );
      return { success: true, msg_status: data.msg_status };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message,
      };
    }
  },
  async removeFoodMerchant({ dispatch }, params) {
    try {
      const { data } = await axios.delete(`/vancynet/food-merchant/${params}`);
      dispatch("fetchFoodMerchants");
      return { success: true };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message,
      };
    }
  },
  async fetchFoodMerchantBranchs({ commit }) {
    try {
      const { data } = await axios.get(`/vancynet/food-merchant-branch`);
      commit("setFoodMerchantBranchsß", data.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchFoodMerchantBranch({ commit }, params) {
    try {
      const { data } = await axios.get(
        `/vancynet/food-merchant-branch/${params}`
      );
      return { success: true, message: data.data };
    } catch (error) {
      return { success: false, message: error.message };
    }
  },
  async storeFoodMerchantBranch({ commit }, params) {
    try {
      const { data } = await axios.post(
        `/vancynet/food-merchant-branch`,
        params
      );
      return { success: true };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message,
      };
    }
  },
  async updateFoodMerchantBranch({ commit }, params) {
    try {
      const { data } = await axios.post(
        `/vancynet/food-merchant-branch/${params.id}`,
        params.body
      );
      return { success: true, msg_status: data.msg_status };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message,
      };
    }
  },
  async removeFoodMerchantBranch({ dispatch }, params) {
    try {
      const { data } = await axios.delete(
        `/vancynet/food-merchant-branch/${params}`
      );
      dispatch("fetchFoodMerchantBranchs");
      return { success: true };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message,
      };
    }
  },
};
